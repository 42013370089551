<template>
  <FilterOptions label="Filter by Location">
    Biome
    <Dropdown
      class="w-full"
      :options="biomes"
      placeholder="Select Biome"
      :scroll-height="DROPDOWN_SCROLL_HEIGHT"
      v-model="biomeRef"
    />
    <small>The biome can also be selected by clicking on the map.</small>
    <div class="clear-filter-button">
      <Button
        class="p-button-warning w-full"
        label="Clear Location Filters"
        @click="clearFilters"
      />
    </div>
  </FilterOptions>
</template>

<script>
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import { ref, watch } from "vue";

import FilterOptions from "./FilterOptions.vue";
import filterOptions from "@/assets/json/filter-options.json";
import store from "@/utils/store";
import { DROPDOWN_SCROLL_HEIGHT } from "@/utils/constants";

export default {
  name: "LocationFilter",
  components: {
    Button,
    Dropdown,
    FilterOptions,
  },
  setup() {
    const biomes = filterOptions["biomes"];

    const biomeRef = ref();

    watch(biomeRef, () => {
      store.commit("setBiome", biomeRef.value);
    });

    watch(
      () => store.state.biome,
      () => {
        biomeRef.value = store.state.biome;
      }
    );

    function clearFilters() {
      biomeRef.value = null;
    }

    return {
      DROPDOWN_SCROLL_HEIGHT,
      biomes,
      biomeRef,
      clearFilters,
    };
  },
};
</script>
