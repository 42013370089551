import store from "@/utils/store";

export function exportAllResultsCsv() {
    let blob = new Blob([convertToCsv(store.state.searchResults)], {
        type: "text/csv;charset=utf-8",
    });
    startDownload(blob);
}

export function exportResultCsv() {
    let blob = new Blob([convertToCsv([store.state.dataModalSpecies])], {
        type: "text/csv;charset=utf-8",
    });
    startDownload(blob);
}

export function startDownload(blob) {
    const a = document.createElement("a");
    a.style.visibility = "hidden";
    a.setAttribute("href", URL.createObjectURL(blob));
    a.setAttribute("download", "export.csv");

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

// From eeqk on Stack Overflow: https://stackoverflow.com/a/58769574
function convertToCsv(data) {

    let keys = [Object.keys(data[0])].concat(data);

    for (let key in keys[1]) {
        if (keys[1][key] === 'NULL') {

            let index = keys[0].indexOf(key);

            //console.log("DELETEING: " + keys[0][index]);
            keys[0].splice(index, 1);
            //console.log("DELETING: " + keys[1][key]);
            delete keys[1][key];
        }
    }

    //console.log("KEYS: ", keys);
    //console.log("Types: ", keys[0]);
    //console.log("Values: ", keys[1]);

    return keys
        .map((o) => {
            return Object.values(o).toString();
        })
        .join("\r\n");
}
