<template>
  <div class="result">
    <b>{{ result.Variety_Name }}</b>
    <br />
    PV Number: {{ result.PVPO_Number }}
    <br />
    Crop Kind: {{ result.Crop_Kind }}
    <br />
    Status: {{ result.Status }}
    <br />
    <span @click="displayDetails" class="clickable-text">More details</span>
  </div>
</template>

<script>
import store from "@/utils/store";

export default {
  name: "DataItem",
  props: {
    result: Object,
  },
  setup(props) {
    function displayDetails() {
      store.commit("toggleShowDataModal");
      store.commit("setDataModalSpecies", props.result);
    }

    return {
      displayDetails,
    };
  },
};
</script>

<style>
.even {
  background-color: var(--gray-50);
}

.result {
  padding: 12px 16px;
}
</style>
