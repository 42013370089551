<template>
  <FilterOptions label="Filter by Identifier">
    PV Number
    <span class="p-input-icon-right w-full">
      <i class="pi pi-search" />
      <InputText
        class="w-full"
        placeholder="Enter PV Number"
        type="text"
        v-model="pvpoNumberRef"
      />
    </span>
    Variety Name
    <span class="p-input-icon-right w-full">
      <i class="pi pi-search" />
      <InputText
        class="w-full"
        placeholder="Enter Variety Name"
        type="text"
        v-model="varietyNameRef"
      />
    </span>
    <div class="clear-filter-button">
      <Button
        class="p-button-warning w-full"
        label="Clear Identifier Filters"
        @click="clearFilters"
      />
    </div>
  </FilterOptions>
</template>

<script>
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import { ref, watch } from "vue";

import FilterOptions from "./FilterOptions.vue";
import store from "@/utils/store";

export default {
  name: "IDFilters",
  components: {
    Button,
    FilterOptions,
    InputText,
  },
  setup() {
    const pvpoNumberRef = ref();
    const varietyNameRef = ref();

    watch(pvpoNumberRef, () => {
      store.commit("setPvpoNumber", pvpoNumberRef.value);
    });

    watch(
      () => store.state.pvpoNumber,
      () => {
        pvpoNumberRef.value = store.state.pvpoNumber;
      }
    );

    watch(varietyNameRef, () => {
      store.commit("setVarietyName", varietyNameRef.value);
    });

    watch(
      () => store.state.varietyName,
      () => {
        varietyNameRef.value = store.state.varietyName;
      }
    );

    function clearFilters() {
      pvpoNumberRef.value = null;
      varietyNameRef.value = null;
    }

    return {
      pvpoNumberRef,
      varietyNameRef,
      clearFilters,
    };
  },
};
</script>
