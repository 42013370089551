<template>
  <Button
    class="p-button-text"
    :class="selected ? 'filter-button-selected' : 'filter-button'"
    icon="pi pi-chevron-down"
    icon-pos="right"
    :label="label"
    @click="toggleOptions"
  />
  <OverlayPanel
    appendTo="body"
    ref="showOptions"
    style="width: 300px"
    @hide="toggleSelected"
    @show="toggleSelected"
  >
    <slot></slot>
  </OverlayPanel>
</template>

<script>
import Button from "primevue/button";
import OverlayPanel from "primevue/overlaypanel";
import { ref } from "vue";

export default {
  name: "FilterOptions",
  components: {
    Button,
    OverlayPanel,
  },
  props: {
    label: String,
  },
  setup() {
    const selected = ref(false);
    const showOptions = ref();

    function toggleSelected() {
      selected.value = !selected.value;
    }

    function toggleOptions(event) {
      showOptions.value.toggle(event);
    }

    return {
      selected,
      showOptions,
      toggleSelected,
      toggleOptions,
    };
  },
};
</script>

<style scoped>
.filter-button.p-button {
  color: white;
}

.filter-button.p-button:enabled:hover {
  color: white;
}

.filter-button-selected.p-button {
  background: white;
  color: var(--blue-800);
}

.filter-button-selected.p-button:enabled:hover {
  background: white;
  color: var(--blue-800);
}
</style>
